export const Payment = (props) => {
  return (
    <div id='payment' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title mb-0'>
          <h2>Payment</h2>
        </div>
        <div className='row'>
          {/* {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-xs-6 col-md-3'>
                  {''}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : 'Loading...'} */}
          <form name="PrePage" method="post" action="https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx">
            <input type="hidden" name="LinkId" value="28e61834-8a44-448b-8d62-cdb879024427" />
            <input type="image" src="img/Pay-now-button.png" />
          </form>

          {/* <form>
            <div class="row">
              <div class="form-group col-md-2"></div>
              <div class="col-md-2">
                <label for="firstName">First name</label>
                <input type="text" class="form-control" id="firstName" required />
              </div>
              <div class="form-group col-md-2">
                <label for="lastName">Last name</label>
                <input type="text" class="form-control" id="lastName" required />
              </div>
              <div class="form-group col-md-4">
                <label for="company">Company/Organization</label>
                <input type="text" class="form-control" id="company" required />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-2"></div>
              <div class="form-group col-md-4">
                <label for="add1">Address</label>
                <input type="text" class="form-control" id="add1" required />
              </div>
              <div class="form-group col-md-4">
                <label for="add2">Address 2</label>
                <input type="text" class="form-control" id="add2" />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-3"></div>
              <div class="form-group col-md-3">
                <label for="city">City</label>
                <input type="text" class="form-control" id="city" required />
              </div>
              <div class="form-group col-md-3">
                <label for="state">State</label>
                <select id="state" class="form-control" required>
                  {props.data
                    ? props.data.map((abb, i) => (
                      <option>{abb.abbreviation}</option>
                    )) : 'Loading'}
                </select>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-3"></div>
              <div class="form-group col-md-3">
                <label for="zipcode">Zip</label>
                <input type="text" class="form-control" id="zipcode" required />
              </div>
              <div class="form-group col-md-3">
                <label for="country">Country</label>
                <input type="readonly" class="form-control" id="country" value="U.S." readOnly />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-3"></div>
              <div class="form-group col-md-3">
                <label for="ccnum">Card Number</label>
                <input type="text" class="form-control" id="ccnum" required />
              </div>
              <div class="form-group col-md-3">
                <label for="cvv">CVV</label>
                <input type="text" class="form-control" id="cvv" placeholder="CVV" required />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-3"></div>
              <div class="form-group col-md-3">
                <label for="exp">Expiration</label>
                <input type="text" class="form-control" id="exp" placeholder="MM/YY" required />
              </div>
              <div class="form-group col-md-3">
                <label for="amt">Amount</label>
                <input type="text" class="form-control" id="amt" required />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-5"></div>
              <button type="submit" class="btn btn-primary col-md-2">Submit</button>
            </div>
          </form> */}
        </div>
      </div>
    </div>

  )
}
