export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Our Services</h2>
          <p>
            Please contact us for other health care services
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-md-3'>
                {' '}
                <i className={d.icon}></i>
                <div className='service-desc'>
                  <h3><b>{d.name}</b></h3>
                  {/* <p>{d.text}</p> */}
                  <ul>
                    {d.text.map(item => {
                      return <li>{item}</li>
                    })}
                  </ul>
                  {/* <p>{d.text}</p> */}
                </div>
              </div>
            ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
